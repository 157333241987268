import React, { useState } from "react"
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText
} from 'reactstrap'

import { Link } from "gatsby"
import Logo from "./logo-dark"

const Navigation = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <div className="navbar-container">
        <nav className="navbar navbar-expand-lg py-1 navbar-dark">
          <div className="container-fluid">
            <Link className="navbar-brand" to="/">
              <Logo />
            </Link>
            <NavbarToggler onClick={toggle} />
            <Collapse className="justify-content-end" isOpen={isOpen} navbar>
              <Nav navbar>
                <div className="py-0 py-lg-0">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <Link to="/about-us/" className="nav-link">
                        About us
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/services/" className="nav-link">
                        Services
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/invest-in-rwanda/"
                        className="nav-link"
                      >
                        Invest in Rwanda
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/institution-ranking/"
                        className="nav-link"
                      >
                        Institution Ranking
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/exchange-programs/"
                        className="nav-link"
                      >
                        Exchange programs
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/partnership/" className="nav-link">
                        University partnership
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/education-system/"
                        className="nav-link"
                      >
                        Academics
                      </Link>
                    </li>
                  </ul>
                </div>
              </Nav>
            </Collapse>
          </div>
        </nav>
      </div>
    </div>
  )
}

export default Navigation
