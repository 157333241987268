import React from "react"
import { Link } from "gatsby"

const navfooter = () => {
  return (
    <div className="bg-gray-800 text-light">
      <div className="py-6 container-fluid">
        <div className="row mt-4">
          <div className="col-6">
            <h5>
              <Link className="text-light" to="/">Study in Rwanda</Link>
            </h5>
            <p className="pr-xl-3">
              <ul className="nav flex-column">
                <li>P.O Box: 1577 Kigali – Rwanda</li>
                <li>
                  Nyarutarama MTN Centre 1<sup>st</sup> floor
                </li>
                <li>KG 9, Ave Kigali</li>
                <br />
                <li>
                  <a className="text-light" href="mailto:info@studyinrwanda.rw">info@studyinrwanda.rw</a>
                </li>
                <li>
                  <a className="text-light" href="mailto:partnerships@sir.rw">partnerships@sir.rw</a>
                </li>
              </ul>
            </p>
          </div>
          <div className="col-6 col-lg">
            {/* <h5>Contact</h5> */}
            <p className="pr-xl-3">
              <ul className="nav flex-column">
                <li><Link className="text-light" to="/about-us">About SIR</Link></li>
                <li>
                  <Link className="text-light"  to="/events">Events</Link>
                </li>
                <li>
                  <Link className="text-light"  to="/contact-us">Find us</Link>
                </li>
                <li>
                  <Link className="text-light"  to="/news">News</Link>
                </li>
                <li>
                  <Link className="text-light"  to="/faq">Information</Link>
                </li>
              </ul>
            </p>
          </div>
          <div className="col-6 col-lg">
            {/* <h5>Contact</h5> */}
            <p className="pr-xl-3">
              <ul className="nav flex-column">
                <li>
                  <Link className="text-light"  to="/invest-in-rwanda">Invest in Rwanda</Link>
                </li>
                <li>
                  <Link className="text-light"  to="/education-system">Education system</Link>
                </li>
                <li>
                  <Link className="text-light"  to="/partnership">For partnership</Link>
                </li>
                <li>
                  <Link className="text-light"  to="/faq">FAQ</Link>
                </li>
                <li>
                  <Link className="text-light"  to="/services">Services</Link>
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default navfooter
