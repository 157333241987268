import React from "react"
import Navfooter from "./navfooter"

const footer = () => {
  return (
    <footer className="text-light">
      <Navfooter />
      <div className="bg-gray-900">
        <div className="py-4 container-fluid">
          <div className="row">
            <div className="col">
              <small className="text-muted">
                &copy;{` `}
                {new Date().getFullYear()}
                {` `} Study in Rwanda
              </small>
            </div>
            {/* <div className="col-auto">
              <ul class="nav small">
                <li class="nav-item">
                  <a href="#" class="nav-link">
                    Accessibility
                  </a>
                </li>
                <li class="nav-item">
                  <a href="#" class="nav-link">
                    Data protection
                  </a>
                </li>
                <li class="nav-item">
                  <a href="#" class="nav-link">
                    Legal information and Compliance
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default footer
