import React from "react"
import { Link } from "gatsby"

const topnav = () => {
  return (
    <div>
      <section className="py-0 text-uppercase ">
        <div className="container-fluid">
          <div className="row justify-content-between align-items-center">
            <div className="col-auto ml-auto">
              <ul className="nav font-size-sm">
              <li className="nav-item">
                  <a className="nav-link text-muted" href="https://apply.sir.rw" target="_blank">
                    apply now
                  </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link text-muted" to="/news/growth-of-education-system">
                    Our motivation
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link text-muted" to="/events">
                    events
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link text-muted" to="/news">
                    news
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link text-muted" to="/faq">
                    faq
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link text-muted" to="/contact-us/">
                    contact us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default topnav
