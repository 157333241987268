import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Topnav from "./topnav"
import Nav from "./nav"

const Header = ({ siteTitle }) => (
  <header className="bg-dark py-2 ">
    <Topnav />
    <Nav />
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
